import React, {Suspense, lazy} from 'react';

import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Loader from "./Loader"
import ErrorFallback from './Errorboundary';
import { ErrorBoundary } from 'react-error-boundary';
const Layout = lazy(() => import('./Layout'));
const Pages = lazy(() => import('./pages/Pages'));




function App() {



  return (
    <div className="app">
    <BrowserRouter>
    {/* // <ErrorBoundary fallback={ErrorFallback}> */}

    <Suspense fallback={<Loader/>}>
    <Layout>
      <Pages/>
    </Layout>
    </Suspense>
    {/* </ErrorBoundary> */}
    </BrowserRouter>

    </div>
  );
}

export default App;
